import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [themeName, setThemeName] = useState('light');

  useEffect(() => {
    // Initial theme check
    const savedTheme = localStorage.getItem('themeName') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    setThemeName(savedTheme);

    // Listener for system theme change
    const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleThemeChange = (e) => {
      const newTheme = e.matches ? 'dark' : 'light';
      localStorage.setItem('themeName', newTheme);
      setThemeName(newTheme);
    };
    darkMediaQuery.addEventListener('change', handleThemeChange);
    return () => darkMediaQuery.removeEventListener('change', handleThemeChange);
  }, []);

  const toggleTheme = () => {
    const newTheme = themeName === 'dark' ? 'light' : 'dark';
    localStorage.setItem('themeName', newTheme);
    setThemeName(newTheme);
  };

  // MUI theme setup
  const theme = createTheme({
    palette: {
      mode: themeName, // 'light' or 'dark'
    },
  });

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme }}>
      <MUIThemeProvider theme={theme}>
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ThemeProvider, ThemeContext };
