import './Dashboard.css'

const Dashboard = () => (
  <div className='dashboard'>
    <iframe 
      src="https://app.powerbi.com/view?r=eyJrIjoiMjJjNTFhMmMtMDhlNi00MTc0LTk5MmQtOGMzNTcwODc0YTlkIiwidCI6Ijg4ODlkMTdhLTRlYTAtNDQwOC1hYTkzLWZiMWExMmZhNDRhNyJ9" 
      title="Dashboard"
      width="100%" 
      height="100%" 
      style={{border: "none"}}
    />
  </div>
);

export default Dashboard;
