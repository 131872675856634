import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { header } from "../../portfolio";
import "./Header.css";
import { LanguageContext } from '../../contexts/language';
import Navbar from "../Navbar/Navbar";


const Header = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);
  const location = useLocation();

  // Access the dynamic title based on the current language
  const title = header.titles[language];
  const { homepage } = header;

  // Determine display language for toggle
  const displayLanguage = language === 'english' ? 'Français' : 'English';

  return (
    <header className="header center">
      <h3>
        {homepage && (
          <a href={homepage} className="link">{title}</a>
        )}
      </h3>

      <div className="nav-container">
      <ul
        className='nav__list'
      >
      {/* Conditionally render Navbar if not on the dashboard page */}
      {location.pathname !== "/dashboard" && <Navbar />}
      {/* Always show language toggle */}
      <li className='nav__list-item'>
        <a
          href='/'
          onClick={toggleLanguage}
          className='link link--lang'
        >
          {displayLanguage}
        </a>
      </li>
      </ul>
      </div>
    </header>
  );
};

export default Header;
