import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  // Detect browser or system language
  const defaultLanguage = navigator.language.startsWith('fr') ? 'french' : 'english';

  // Initial language can be set based on user preference or browser settings
  const [language, setLanguage] = useState(localStorage.getItem('language') || defaultLanguage);

  useEffect(() => {
    // Update localStorage when language changes
    localStorage.setItem('language', language);
  }, [language]);

  const toggleLanguage = (event) => {
    event.preventDefault(); // Prevent the link from causing a page reload
    setLanguage((currentLanguage) => (currentLanguage === 'english' ? 'french' : 'english'));
  };
  

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LanguageProvider, LanguageContext };
