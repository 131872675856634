import { render } from 'react-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import { ThemeProvider } from './contexts/theme';
import { LanguageProvider } from './contexts/language';
import './index.css';

render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);
