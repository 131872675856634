import { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeContext } from './contexts/theme';
// import { LanguageContext } from './contexts/language'; 
import Header from './components/Header/Header';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Dashboard from './components/Dashboard/Dashboard';
import Skills from './components/Skills/Skills';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import './App.css';

const App = () => {
  const { themeName } = useContext(ThemeContext);
  // const { language } = useContext(LanguageContext);

  return (
    <Router>
      <div id='top' className={`${themeName} app`}>
        <Header />
        <main>
          <Routes> {/* Correctly wrapping Route components with Routes */}
            <Route path="/" element={<><About /><Projects /><Skills /><Contact /></>} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </main>
        <ScrollToTop />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
